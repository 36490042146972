class ScrollToTop {
	constructor(options) {
		this.targetSelector = options.targetSelector;
		this.scrollToTopBtn = document.getElementById(options.scrollToTopButtonId);
		if (this.scrollToTopBtn) {
			this.setupScrollToTopButton();
			this.setupIntersectionObserver();
		}
	}

	setupScrollToTopButton() {
		this.scrollToTopBtn.addEventListener("click", () => {
			document.documentElement.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		});
	}

	setupIntersectionObserver() {
		const target = document.querySelector(this.targetSelector);
		if (target) {
			let observer = new IntersectionObserver((entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						this.scrollToTopBtn.classList.remove("active");
					} else {
						this.scrollToTopBtn.classList.add("active");
					}
				});
			});

			observer.observe(target);
		}
	}
}

export default ScrollToTop;