class VideoAutoplay {
    constructor(video) {
        this.video = video;
        // Перевірка, чи підтримує браузер автовідтворення при наявності звуку
        var promise = this.video.play();
        if (promise !== undefined) {
            promise.then(function () {
                // Відео успішно відтворено зі звуком, дозволяємо автовідтворення
                this.video.muted = true;
                this.video.play();
            }.bind(this)).catch(function (error) {
                // Відтворення без звуку, так як автовідтворення зі звуком не підтримується
                this.video.muted = true;
            }.bind(this));
        }

        // Відстеження видимості відео на сторінці
        var options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.5
        };

        this.observer = new IntersectionObserver(this.handleIntersection.bind(this), options);
        this.observer.observe(this.video);
    }

    handleIntersection(entries, observer) {
        entries.forEach(function (entry) {
            if (entry.isIntersecting) {
                // Відео увійшло в зону видимості, запускаємо відтворення
                this.video.play();
            } else {
                // Відео поза зоною видимості, зупиняємо відтворення
                this.video.pause();
            }
        }.bind(this));
    }
}

export default VideoAutoplay;